const userKey = "userData"

export const getUser = () => {
    const savedUser = localStorage.getItem(userKey)

    if (savedUser) return JSON.parse(savedUser)

    return null
}

export const setUser = user => localStorage.setItem(userKey, JSON.stringify(user))

export const deleteUser = () => localStorage.removeItem(userKey)
