import React, { Fragment, useState } from 'react'
import { Loader, Message, Statistic, List, Header, Button, Segment } from 'semantic-ui-react'

import Flush from '../components/Flush'
import useApi from '../../../api/useApi'
import { getCacheStats } from '../../../api/services/admin'

const Cache = () => {
    const [refresh, setRefresh] = useState(false)
    const [showMB, setShowMB] = useState(false)
    const [isLoading, error, stats] = useApi(getCacheStats, [refresh])

    return (
        <Fragment>
            <Header as="h2" >Octaos API cache flush</Header>
            <Flush />

            <Header as="h2" >Octaos API cache statistics</Header>
            <Button icon="refresh" disabled={isLoading} onClick={() => setRefresh(!refresh)} />
            <Button content="Show in megabytes" toggle active={showMB} onClick={() => setShowMB(!showMB)} />

            {isLoading && <Segment basic ><Loader active /></Segment>}
            {error && <Message error content={<pre>{JSON.stringify(error, null, 4)}</pre>} />}

            {!isLoading && !error && stats &&
                <Fragment>
                    <Header as="h3" >Stats</Header>
                    <Statistic.Group widths='five'>
                        <Statistic>
                            <Statistic.Value>{stats.stats.hits}</Statistic.Value>
                            <Statistic.Label>Hits</Statistic.Label>
                        </Statistic>
                        <Statistic>
                            <Statistic.Value>{stats.stats.misses}</Statistic.Value>
                            <Statistic.Label>Misses</Statistic.Label>
                        </Statistic>
                        <Statistic>
                            <Statistic.Value>{stats.stats.keys}</Statistic.Value>
                            <Statistic.Label>Keys</Statistic.Label>
                        </Statistic>
                        {showMB ?
                            <Statistic>
                                <Statistic.Value>{(stats.stats.ksize * 0.0000009536743164).toFixed(4)}</Statistic.Value>
                                <Statistic.Label>Key size (megabytes)</Statistic.Label>
                            </Statistic>
                            :
                            <Statistic>
                                <Statistic.Value>{stats.stats.ksize}</Statistic.Value>
                                <Statistic.Label>Key size (bytes)</Statistic.Label>
                            </Statistic>
                        }
                        {showMB ?
                            <Statistic>
                                <Statistic.Value>{(stats.stats.vsize * 0.0000009536743164).toFixed(4)}</Statistic.Value>
                                <Statistic.Label>Value size (megabytes)</Statistic.Label>
                            </Statistic>
                            :
                            <Statistic>
                                <Statistic.Value>{stats.stats.vsize}</Statistic.Value>
                                <Statistic.Label>Value size (bytes)</Statistic.Label>
                            </Statistic>
                        }


                    </Statistic.Group>

                    <Header as="h3" >Keys</Header>
                    <List>
                        {stats.keys.map(key => (
                            <List.Item key={key} >
                                {key}
                            </List.Item>
                        ))}
                    </List>
                </Fragment>
            }
        </Fragment>
    )
}

export default Cache