import React, { Fragment, Component } from 'react'
import { Button, Message, Icon } from 'semantic-ui-react'

import { productReindex, productImageRebuild, glassfilterReindex, productIconReindex } from '../../../api/services/product'

class Reindex extends Component {
    state = {
        loadingProductReindex: false,
        loadingProductImageRebuild: false,
        loadingGlassfilterReindex: false,
        loadingProductIconReindex: false,
        showMessage: false,
        message: "Process started",
        error: null,
    }

    showMessage = () => {
        this.setState({ showMessage: true }, () => {
            setTimeout(() => this.setState({ showMessage: false }), 10000)
        })
    }

    getMessage = data => {
        const { msg, status, total, success, failure } = data
        let message = "Process started"
        if (msg) {
            message = msg
        } else if (status) {
            message = status
        }

        if (total !== undefined && success !== undefined && failure !== undefined)
            message = `${message}\nTotal: ${success}\nSuccess: ${success}\nFailure: ${failure}`

        return message
    }

    productReindex = () => {
        this.setState({ loadingProductReindex: true })
        productReindex()
            .then(res => {
                if (res.status === 200) {
                    this.setState({ message: this.getMessage(res.data) }, this.showMessage)
                } else {
                    this.setState({ error: res })
                }
            })
            .catch(e => this.setState({ error: e }))
            .then(() => this.setState({ loadingProductReindex: false }))
    }

    productImageRebuild = () => {
        this.setState({ loadingProductImageRebuild: true })
        productImageRebuild()
            .then(res => {
                if (res.status === 200) {
                    this.setState({ message: this.getMessage(res.data) }, this.showMessage)
                } else {
                    this.setState({ error: res })
                }
            })
            .catch(e => this.setState({ error: e }))
            .then(() => this.setState({ loadingProductImageRebuild: false }))
    }

    glassfilterReindex = () => {
        this.setState({ loadingGlassfilterReindex: true })
        glassfilterReindex()
            .then(res => {
                if (res.status === 200 || res.status === 304) {
                    this.setState({ message: this.getMessage(res.data) }, this.showMessage)
                } else {
                    this.setState({ error: res })
                }
            })
            .catch(e => this.setState({ error: e }))
            .then(() => this.setState({ loadingGlassfilterReindex: false }))
    }

    productIconReindex = () => {
        this.setState({ loadingProductIconReindex: true })
        productIconReindex()
            .then(res => {
                if (res.status === 200) {
                    this.setState({ message: this.getMessage(res.data) }, this.showMessage)
                } else {
                    this.setState({ error: res })
                }
            })
            .catch(e => this.setState({ error: e }))
            .then(() => this.setState({ loadingProductIconReindex: false }))
    }

    render() {
        const { loadingProductReindex, loadingProductImageRebuild, loadingGlassfilterReindex, loadingProductIconReindex, showMessage, error, message } = this.state
        return (
            <Fragment>
                <Button.Group color="orange" widths="4" >
                    <Button
                        animated="fade"
                        loading={loadingProductReindex}
                        disabled={loadingProductReindex}
                        onClick={this.productReindex}
                    >
                        <Button.Content visible >Product Reindex</Button.Content>
                        <Button.Content hidden ><Icon name="refresh" /></Button.Content>
                    </Button>

                    <Button
                        animated="fade"
                        loading={loadingProductImageRebuild}
                        disabled={loadingProductImageRebuild}
                        onClick={this.productImageRebuild}
                    >
                        <Button.Content visible >Product Image Rebuild</Button.Content>
                        <Button.Content hidden ><Icon name="refresh" /></Button.Content>
                    </Button>

                    <Button
                        animated="fade"
                        loading={loadingGlassfilterReindex}
                        disabled={loadingGlassfilterReindex}
                        onClick={this.glassfilterReindex}
                    >
                        <Button.Content visible >Glassfilter Reindex</Button.Content>
                        <Button.Content hidden ><Icon name="refresh" /></Button.Content>
                    </Button>

                    <Button
                        animated="fade"
                        loading={loadingProductIconReindex}
                        disabled={loadingProductIconReindex}
                        onClick={this.productIconReindex}
                    >
                        <Button.Content visible >Product Icon Reindex</Button.Content>
                        <Button.Content hidden ><Icon name="refresh" /></Button.Content>
                    </Button>
                </Button.Group>
                {showMessage && <Message success content={<div style={{ whiteSpace: 'pre' }} >{message}</div>} />}
                {error && <Message error content={<pre>{JSON.stringify(error, null, 4)}</pre>} />}
            </Fragment>
        )
    }
}

export default Reindex
