import request from '../request';

export const getReports = (guid) => request({
    url: '/report',
    method: 'GET',
}, guid);

export const getReportInfo = (name, guid) => request({
    url: `/report/${name}`,
    method: 'GET',
}, guid);

export const getReport = (name, data, guid) => request({
    url: `/report/${name}`,
    method: 'POST',
    data,
}, guid);
