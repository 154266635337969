import React, { Fragment, Component } from 'react'

import { Grid, Segment, Responsive } from 'semantic-ui-react'

import DashboardHeader from './components/DashboardHeader'
import Menu from './components/Menu'

import Dashboard2 from './pages/Dashboard'
import Manufacturers from './pages/Manufacturers'
import Users from './pages/Users2'
import CalwinReports from './pages/CalwinReports'
import ApiUsers from './pages/ApiUsers2'
import CalwinServers from './pages/CalwinServers'
import UserGroups from './pages/UserGroups'
import SystemAdmins from './pages/SystemAdmins'
import Modules from './pages/Modules'
import Swagger from './pages/Swagger'
import CalwinResources from './pages/CalwinResources'
import ExternalLinks from './pages/ExternalLinks';
import DevTools from './pages/DevTools';
import Cache from './pages/Cache';
import Testing from './pages/Testing'


class Dashboard extends Component {
    state = {
        activeItem: localStorage.getItem("activeItem") ? localStorage.getItem("activeItem") : "dashboard"
    }

    handleItemClick = name => {
        this.setState({ activeItem: name })
        localStorage.setItem("activeItem", name)
    }

    router = () => {
        const { activeItem } = this.state

        switch (activeItem) {
            case "dashboard":
                return <Dashboard2 />
            case "manufacturers":
                return <Manufacturers />
            case "users":
                return <Users />
            case "calwinReports":
                return <CalwinReports />
            case "apiUsers":
                return <ApiUsers />
            case "calwinServers":
                return <CalwinServers />
            case "userGroups":
                return <UserGroups />
            case "systemAdmins":
                return <SystemAdmins />
            case "modules":
                return <Modules />
            case "swagger":
                return <Swagger />
            case "calwinResources":
                return <CalwinResources />
            case "externalLinks":
                return <ExternalLinks />
            case "devTools":
                return <DevTools />
            case "cache":
                return <Cache />
            case "testing":
                return <Testing />
            default:
                return <Dashboard2 />
        }
    }

    render() {
        const { activeItem } = this.state

        return (
            <Fragment>
                <Grid stackable >
                    <Grid.Column width="3" >
                        <Menu activeItem={activeItem} onItemClick={this.handleItemClick} />
                    </Grid.Column>
                    <Responsive maxWidth="767" >
                        <div style={{ height: 50 }} />
                    </Responsive>
                    <Grid.Column style={{ marginTop: 42 }} stretched width="12" >
                        <DashboardHeader />
                        <Segment basic padded >
                            {this.router()}
                        </Segment>
                    </Grid.Column>
                </Grid>
            </Fragment>
        )
    }
}

export default Dashboard
