import request from '../request';

export const glassfilterReindex = () => request({
    url: '/product/glassfilter/reindex',
    method: 'POST',
});

export const productReindex = data => request({
    url: '/product/reindex',
    method: 'POST',
    data,
});

export const productImageRebuild = data => request({
    url: '/product/image/rebuild',
    method: 'POST',
    data,
});

export const productIconReindex = () => request({
    url: '/product/icon/reindex',
    method: 'POST',
});
