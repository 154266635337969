import React, { Fragment, Component } from 'react'

import { Menu, Segment, Responsive, Button } from 'semantic-ui-react'

import Header from '../../Header'

class MyMenu extends Component {
    state = {
        showItems: false,
    }

    handleItemClick = (e, { name }) => this.props.onItemClick(name)

    getColor = () => {
        const environment = localStorage.getItem("environment")

        if (environment) {
            if (environment === "local") return "black"
            if (environment === "dev") return "red"
            if (environment === "stg") return "blue"
            if (environment === "prod") return "green"
        } else {
            return "black"
        }
    }

    getEnv = () => {
        const env = localStorage.getItem("environment")
        if (env) {
            const envText = env.charAt(0).toUpperCase() + env.slice(1)
            const localProd = localStorage.getItem("localProd")

            const isLocalProd = env === "local" && localProd === "true"

            if (isLocalProd) return `${envText} (Prod)`

            return envText
        }
        else
            return ""
    }

    renderMenuItems = (mobile = false) => {
        const { activeItem } = this.props
        const { showItems } = this.state

        return (
            <Fragment>
                {mobile &&
                    <Segment basic >
                        <Button
                            basic
                            color="grey"
                            floated="right"
                            icon="bars"
                            onClick={() => this.setState({ showItems: !showItems })}
                        />
                    </Segment>
                }
                {/* {!mobile && !showItems &&
                    <Button icon="arrow alternate circle down" onClick={() => this.setState({ showItems: true })} />
                } */}
                {(showItems || !mobile) &&
                    <Fragment>
                        <Segment basic inverted color={this.getColor()} textAlign="center" >
                            {this.getEnv()}
                        </Segment>
                        <Segment basic onClick={() => this.props.onItemClick("dashboard")} >
                            <Header alt animation="pulse" />
                        </Segment>
                        <Menu.Item
                            name="dashboard"
                            active={activeItem === "dashboard"}
                            onClick={this.handleItemClick}
                        />
                        <Menu.Item
                            name="octaosSupport"
                            href="https://octaos.zendesk.com"
                            target="_blank"
                        />
                        <Menu.Item
                            name="manufacturers"
                            active={activeItem === "manufacturers"}
                            onClick={this.handleItemClick}
                        />
                        <Menu.Item
                            name="users"
                            active={activeItem === "users"}
                            onClick={this.handleItemClick}
                        />
                        <Menu.Item
                            name="calwinReports"
                            active={activeItem === "calwinReports"}
                            onClick={this.handleItemClick}
                        />
                        <Menu.Item
                            name="apiUsers"
                            active={activeItem === "apiUsers"}
                            onClick={this.handleItemClick}
                        />
                        <Menu.Item
                            name="calwinServers"
                            active={activeItem === "calwinServers"}
                            onClick={this.handleItemClick}
                        />
                        <Menu.Item
                            name="userGroups"
                            active={activeItem === "userGroups"}
                            onClick={this.handleItemClick}
                        />
                        <Menu.Item
                            name="systemAdmins"
                            active={activeItem === "systemAdmins"}
                            onClick={this.handleItemClick}
                        />
                        <Menu.Item
                            name="modules"
                            active={activeItem === "modules"}
                            onClick={this.handleItemClick}
                        />
                        <Menu.Item
                            name="swagger"
                            active={activeItem === "swagger"}
                            onClick={this.handleItemClick}
                        />
                        <Menu.Item
                            name="calwinResources"
                            active={activeItem === "calwinResources"}
                            onClick={this.handleItemClick}
                        />
                        <Menu.Item
                            name="externalLinks"
                            active={activeItem === "externalLinks"}
                            onClick={this.handleItemClick}
                        />
                        <Menu.Item
                            name="devTools"
                            active={activeItem === "devTools"}
                            onClick={this.handleItemClick}
                        />
                        <Menu.Item
                            name="cache"
                            active={activeItem === "cache"}
                            onClick={this.handleItemClick}
                        />
                        <Menu.Item
                            name="testing"
                            active={activeItem === "testing"}
                            onClick={this.handleItemClick}
                        />
                        {mobile && <div style={{ marginBottom: 40 }} />}
                    </Fragment>
                }
            </Fragment>
        )
    }

    render() {
        return (
            <Fragment>
                <Responsive minWidth="768" >
                    <Menu
                        style={{ position: 'fixed', top: 0, bottom: 0, borderRadius: 0, overflow: 'auto', maxHeight: '100vh' }}
                        size="large"
                        vertical
                        inverted
                    >
                        {this.renderMenuItems()}
                    </Menu>
                </Responsive>
                <Responsive maxWidth="767" >
                    <Menu
                        onClick={() => this.setState({ showItems: !this.state.showItems })}
                        stackable
                        inverted
                        style={{ position: 'fixed', top: 0, left: 0, right: 0, borderRadius: 0, zIndex: 9999, overflow: 'auto', maxHeight: '100vh' }}
                    >
                        {this.renderMenuItems(true)}
                    </Menu>
                </Responsive>
            </Fragment>
        )
    }
}

export default MyMenu
