import request from '../request';

export const flush = () => request({
    url: '/admin/cache/flush',
    method: 'POST',
});

export const flushAll = () => request({
    url: '/admin/cache/flush-all',
    method: 'POST',
});

export const getCacheStats = () => request({
    url: '/admin/cache/stats',
    method: 'GET',
});

export const getCalwinFunctions = () => request({
    url: '/admin/calwin-function',
    method: 'GET',
});

export const runCalwinFunction = (name, params, xMid) => request({
    url: `/admin/calwin-function/${name}`,
    method: 'POST',
    data: { params }
}, xMid);

