import axios from 'axios';
import { getToken, isAuthenticated, deleteToken } from '../helpers/auth';
import { getQuery } from '../helpers/utils';

const getUrl = () => {
    const environment = localStorage.getItem("environment")

    if (environment) {
        if (environment === "local") return "http://localhost:3000"
        if (environment === "dev") return "https://api-dev.octaos.com"
        if (environment === "stg") return "https://api-stg.octaos.com"
        if (environment === "prod") return "https://api.octaos.com"
    } else {
        return "https://osc-api-srv-dev.azurewebsites.net"
    }
}

let apiUrl = getUrl();

const defaultValues = {};
defaultValues.baseURL = apiUrl;
defaultValues.headers = {
    'Content-Type': 'application/json',
};

const client = axios.create(defaultValues);

const request = (options, mid = null) => {

    client.defaults.baseURL = getUrl()

    const environment = localStorage.getItem("environment")
    const localProd = localStorage.getItem("localProd")

    const isLocalProd = environment === "local" && localProd === "true"

    if (isLocalProd) {
        client.defaults.headers["x-mid"] = "2f007c66-bb3c-4808-8589-a010f085a80d"
    }

    if (isAuthenticated()) {
        client.defaults.headers.common.Authorization = `Bearer ${getToken()}`;
        // client.defaults.params = { token: getToken() }
    }

    const xMid = localStorage.getItem("x-mid")

    if ((xMid || mid) && options.url !== "/auth/login" && options.url !== "/user") {
        client.defaults.headers["x-mid"] = mid ? mid : xMid
    }

    const onSuccess = response => response;
    const onError = error => {
        if (
            error.response &&
            error.response.status === 401 &&
            (error.response.data.msgKey === 'token_expired' ||
                error.response.data.msgKey === 'invalid_token')
        ) {
            deleteToken()

            const { token, env, view } = getQuery()
            if (!(token && env && view)) window.location.reload()
        }

        if (error.response) {
            console.error('Status:', error.response.status);
            console.error('Data:', error.response.data);
            console.error('Headers:', error.response.headers);
        } else {
            console.error('Error Message:', error.message);
        }

        return Promise.reject(error.response || error.message);
    };

    return client(options)
        .then(onSuccess)
        .catch(onError);
};

export default request;
