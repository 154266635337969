import React, { Fragment, useState, useEffect } from 'react'
import { Form } from 'semantic-ui-react'

import { getReportInfo } from '../../../../api/services/report'

const ReportParams = ({ reportId, onParamChange, guid }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [reportInfo, setReportInfo] = useState(null);
    const [paramInputs, setParamInputs] = useState({});
    const [types, setTypes] = useState({});

    const initData = reportInfo => {
        const _paramInputs = {};
        const _types = {};

        reportInfo.parameters.forEach(param => {
            _paramInputs[param.name] = param.value ? param.value : "";
            _types[param.name] = param.type;
        })

        setReportInfo(reportInfo);
        setParamInputs(_paramInputs);
        setTypes(_types);
    }

    const fetchReportInfo = () => {
        setLoading(true);

        getReportInfo(reportId, guid)
            .then(res => initData(res.data))
            .catch(setError)
            .then(() => setLoading(false))
    }

    useEffect(() => {
        fetchReportInfo();
    }, [])

    const handleParamChange = (key, value) => {
        const newParamInputs = { ...paramInputs, [key]: value };

        setParamInputs(newParamInputs);

        const data = Object.keys(newParamInputs).map(key => ({ name: key, value: newParamInputs[key], type: types[key] }));

        onParamChange(data);
    }

    const getParamInput = param => {
        switch (param.type) {
            case "date":
                return (
                    <Form.Input
                        type="date"
                        label={param.caption}
                        value={paramInputs[param.name]}
                        onChange={e => handleParamChange(param.name, e.target.value)}
                    />
                )
            case "text":
                return (
                    <Form.Input
                        type="text"
                        label={param.caption}
                        value={paramInputs[param.name]}
                        onChange={e => handleParamChange(param.name, e.target.value)}
                    />
                )
            case "integer":
                return (
                    <Form.Input
                        type="number"
                        label={param.caption}
                        value={paramInputs[param.name]}
                        onChange={e => handleParamChange(param.name, e.target.value)}
                    />
                )
            case "float":
                return (
                    <Form.Input
                        type="text"
                        label={param.caption}
                        value={paramInputs[param.name]}
                        onChange={e => handleParamChange(param.name, e.target.value)}
                    />
                )
            case "select":
            case "selectIndex":
            case "selectSQL":
                const getText = obj => param.type === "selectSQL" ? `(${obj.value}) ${obj.key}` : obj.key

                return (
                    <Form.Dropdown
                        scrolling
                        search
                        selection
                        label={param.caption}
                        placeholder={param.caption}
                        options={param.values.map(obj => ({ key: obj.value, text: getText(obj), value: obj.value }))}
                        value={paramInputs[param.name]}
                        onChange={(_, { value }) => handleParamChange(param.name, value)}
                    />
                )
            default:
                return (
                    <p style={{ color: "red" }} >No support for {param.type}</p>
                )
        }
    }

    return (
        <Fragment>
            {error
                ?
                <pre>{JSON.stringify(error, null, 4)}</pre>
                :
                <Form loading={loading} >
                    {loading && <div style={{ padding: 16 }} />}
                    {reportInfo && reportInfo.title.length <= 0 && <h2 style={{ textAlign: 'center' }} >No parameters</h2>}
                    {reportInfo && reportInfo.title && <h2>{reportInfo.title}</h2>}
                    {!loading && reportInfo && reportInfo.parameters && reportInfo.parameters.map(param =>
                        <Fragment key={param.name} >
                            {getParamInput(param)}
                        </Fragment>
                    )}
                </Form>
            }
        </Fragment>
    )
}

export default ReportParams;
