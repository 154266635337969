import React, { Fragment, Component } from 'react'
import { Table, Button } from 'semantic-ui-react'

import EditModal from '../components/EditModal'
import AddModal from '../components/AddModal'
import Page from '../components/Page'

import {
    getManufacturers,
    updateManufacturer,
    addManufacturer,
    // setManufacturer,
} from '../../../api/services/manufacturer'

class Manufacturers extends Component {
    state = {
        manufacturers: [],
        loading: false,
        filteredList: [],
        error: null,
        settingManufacturer: null,
    }

    componentDidMount() {
        this.getManufacturers()
    }

    getManufacturers = () => {
        this.setState({ loading: true })
        getManufacturers()
            .then(res => this.setState({ manufacturers: res.data, filteredList: res.data }))
            .catch(e => this.setState({ error: e }))
            .then(() => this.setState({ loading: false }))
    }

    setManufacturer = (guid, name) => {
        this.setState({ settingManufacturer: guid })

        localStorage.setItem("x-mid", guid)
        localStorage.setItem("currentManufacturer", name)
        window.location.reload()

        // setManufacturer(guid)
        //     .then(res => {
        //         localStorage.setItem("currentManufacturer", name)
        //         window.location.reload()
        //     })
        //     .catch(e => this.setState({ error: e }))
        //     .then(() => this.setState({ settingManufacturer: null }))
    }

    filterList = text => {
        const { manufacturers } = this.state
        const filteredList = manufacturers.filter(man => {
            const manName = man.name.substring(0, text.length).toLowerCase()

            return manName === text.toLowerCase()
        })

        this.setState({ filteredList })
    }

    handleSave = (guid, inputs) => {
        this.setState({ loading: true })
        updateManufacturer(guid, inputs)
            .then(res => this.getManufacturers())
            .catch(e => this.setState({ loading: false, error: e }))
        // window.alert(JSON.stringify(inputs, null, 4))
    }

    handleAdd = inputs => {
        this.setState({ loading: true })
        addManufacturer(inputs)
            .then(res => this.getManufacturers())
            .catch(e => this.setState({ loading: false, error: e }))
    }

    render() {
        const { filteredList, loading, error, settingManufacturer } = this.state
        return (
            <Page
                icon="industry"
                name="manufacturers"
                length={filteredList.length}
                loading={loading}
                error={error}
                onFilterChange={this.filterList}
                tableHeaders={["ID", "Manufacturer", "Domain", "CalWin Server", "Created", ""]}
                tableBody={() => (
                    <Fragment>
                        {filteredList.map(manufacturer =>
                            <Table.Row key={manufacturer.guid} >
                                <Table.Cell>{manufacturer.id}</Table.Cell>
                                <Table.Cell>{manufacturer.name}</Table.Cell>
                                {/* <Table.Cell>{manufacturer.enabled === 1 ? "Yes" : "No"}</Table.Cell> */}
                                <Table.Cell>(f)</Table.Cell>
                                <Table.Cell>{manufacturer.host}</Table.Cell>
                                <Table.Cell>(f)</Table.Cell>
                                <Table.Cell collapsing>
                                    <EditModal
                                        object={manufacturer}
                                        header={manufacturer.name}
                                        onSave={inputs => this.handleSave(manufacturer.guid, inputs)}
                                    />
                                    <Button
                                        color="purple"
                                        content="Select"
                                        onClick={() => this.setManufacturer(manufacturer.guid, manufacturer.name)}
                                        loading={settingManufacturer && settingManufacturer === manufacturer.guid}
                                        disabled={settingManufacturer !== null}
                                    />
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Fragment>
                )}
                addNew={() => (
                    <AddModal
                        header="manufacturer"
                        fields={["name", "subscriptionPlan", "host", "applicationId", "passphrase", "standardMerchant",]}
                        onSave={this.handleAdd}
                    />
                )}
            />
        )
    }
}

export default Manufacturers
