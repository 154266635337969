import { useState, useEffect } from 'react';

const useCall = (apiCall, deps = [], callCondition = true, callback = null) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);

    const handleCall = () => {
        if (callCondition) {
            setError(null)
            setIsLoading(true)

            apiCall().then(res => {
                setData(res.data)
                if (callback) callback(res.data)
                setIsLoading(false)
            }).catch(e => {
                setError(e)
                setIsLoading(false)
            })
        }
    }

    useEffect(handleCall, deps)

    return [isLoading, error, data]
}

export default useCall