import React, { Component } from 'react'
import { Grid, Header, Image, Button, Statistic } from 'semantic-ui-react'
import md5 from 'md5';

import { logout } from '../../../api/services/auth'
import { deleteToken } from '../../../helpers/auth'
import { deleteUser } from '../../../helpers/storage'

import { UserConsumer } from '../../../UserContext'

class DashboardHeader extends Component {
    state = {
        loading: false,
    }

    handleLogout = () => {
        deleteToken()
        deleteUser()
        window.location.reload()
    }

    logout = () => {
        this.setState({ loading: true, error: null })
        logout()
            .then(res => this.handleLogout())
            .catch(e => this.setState({ error: e }))
            .then(() => this.setState({ loading: false }))
    }

    render() {
        const { loading, error } = this.state
        const user = this.props.context

        return (
            <Grid columns="equal" stackable >
                <Grid.Column width="6" >
                    <Header as="h2" >Octaos admin panel</Header>
                </Grid.Column>
                <Grid.Column textAlign="center" >
                    <Statistic size="mini" color="green" >
                        <Statistic.Label>Manufacturer</Statistic.Label>
                        <Statistic.Value>{localStorage.getItem("currentManufacturer")}</Statistic.Value>
                    </Statistic>
                </Grid.Column>
                {error &&
                    <Grid.Column>
                        <pre style={{ color: 'red' }} >{JSON.stringify(error, null, 4)}</pre>
                        <Button content="Force log out" onClick={this.handleLogout} />
                    </Grid.Column>
                }
                <Grid.Column textAlign="right" width="6" >
                    <Header as="h3" >
                        <Image circular src={`https://www.gravatar.com/avatar/${md5(user ? user.email : "")}?d=mp`} />
                        <Header.Content>
                            <div style={{ textAlign: 'left', width: '100%' }} >{user ? user.name : "..."}</div>
                            <Header.Subheader>
                                <Button
                                    basic
                                    compact
                                    content="Log out"
                                    icon="log out"
                                    labelPosition="left"
                                    loading={loading}
                                    disabled={loading}
                                    onClick={this.logout}
                                />
                            </Header.Subheader>
                        </Header.Content>
                    </Header>
                </Grid.Column>
            </Grid>
        )
    }
}

const MapElement = () => <UserConsumer>{context => <DashboardHeader context={context} />}</UserConsumer>

export default MapElement
