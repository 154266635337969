import React, { Fragment, useState, useEffect } from 'react'
import { Modal, Form, Button, Icon, Message } from 'semantic-ui-react'

import { addUserAttribute } from '../../../../api/services/user'

const DefaultValues = ({ functions, modalOpen, onClose, onSave, defaultParams, getAttrKeyDefaultParams, user }) => {
    const [inputs, setInputs] = useState({})
    const [params, setParams] = useState([])
    const [saving, setSaving] = useState(false)
    const [error, setError] = useState(false)

    useEffect(() => {
        if (functions) {
            const _inputs = {}
            const _params = []

            functions.forEach(func => {
                func.params.forEach(param => {
                    if (!_params.some(p => p.name === param.name)) {
                        _inputs[param.name] = defaultParams[param.name] ? defaultParams[param.name] : ""

                        _params.push({ ...param, function: func.name })
                    }
                })
            })

            setInputs(_inputs)
            setParams(_params)
        }
    }, [functions, setParams, setInputs, defaultParams])

    const handleSave = async () => {
        setError(null)
        setSaving(true)

        try {
            await addUserAttribute(user.id, getAttrKeyDefaultParams(), JSON.stringify(inputs))

            onSave(inputs)
            handleClose()
        } catch (error) {
            setError(error)
        }

        setSaving(false)
    }

    const handleClose = () => {
        if (!saving) {
            setError(null)
            onClose()
        }
    }

    return (
        <Fragment>
            <Modal size="large" open={modalOpen} onClose={handleClose} closeOnEscape={!saving} closeOnDimmerClick={!saving} >
                <Modal.Header>Default params</Modal.Header>

                <Modal.Content scrolling >
                    <Modal.Description>
                        {error && (
                            <Message style={{ overflowX: 'scroll' }} negative >
                                <Message.Header>Error saving data</Message.Header>
                                <pre>{JSON.stringify(error, null, 4)}</pre>
                            </Message>
                        )}

                        <Form>
                            {params.map(param => (
                                <Form.Input
                                    key={param.name}
                                    label={param.name}
                                    placeholder={`Used in ${param.function}`}
                                    value={inputs[param.name]}
                                    onChange={e => setInputs({ ...inputs, [param.name]: e.target.value })}
                                />
                            ))}
                        </Form>
                    </Modal.Description>
                </Modal.Content>

                <Modal.Actions>
                    <Button color="green" disabled={saving} loading={saving} onClick={handleSave} >
                        Save <Icon name='save' />
                    </Button>
                </Modal.Actions>
            </Modal>
        </Fragment>
    )
}

export default DefaultValues
