import React, { Fragment, Component } from 'react'
import axios from 'axios'
import SwaggerUI from 'swagger-ui'
import 'swagger-ui/dist/swagger-ui.css'

import { List, Segment, Header, Loader } from 'semantic-ui-react'

class CalwinResources extends Component {
    constructor(props) {
        super(props)

        this.state = {
            vNumber: null,
            loading: false,
            currentSrc: "https://octaossrv1.calwin.no/doc",
            iframeLoading: false,
        }

        this.iframeElm = React.createRef()
    }

    componentDidMount() {
        this.iframeElm.current.addEventListener('load', () => this.setState({ iframeLoading: false }))

        SwaggerUI({
            dom_id: '#myDomId2',
            url: "https://octaossrv1.calwin.no/api",
        })

        this.setState({ loading: true })
        axios
            .get("https://octaossrv1.calwin.no/ping")
            .then(res => this.setState({ vNumber: res.data, loading: false }))
    }

    handleSrcChange = currentSrc => this.setState({ currentSrc, iframeLoading: true })

    render() {
        const { loading, vNumber, currentSrc, iframeLoading } = this.state


        const link1 = `https://s3.eu-central-1.amazonaws.com/help.calwin.no/${vNumber}/index.html`
        const link2 = `https://s3.eu-central-1.amazonaws.com/help.calwin.no/${vNumber}/releasenotesen/index.html`
        const link3 = "https://octaossrv1.calwin.no/doc"

        return (
            <Fragment>
                <Header
                    as="h2"
                    icon="firefox"
                    content="CalWin Resources"
                />
                <Segment basic>
                    <List selection horizontal verticalAlign="middle" size="big" >
                        <List.Item
                            active={currentSrc === link1}
                            disabled={loading}
                            onClick={() => this.handleSrcChange(link1)}
                        >
                            <List.Header>CalWin Help</List.Header>
                            {vNumber ? `(${vNumber})` : '...'}
                        </List.Item>
                        <List.Item
                            active={currentSrc === link2}
                            disabled={loading}
                            onClick={() => this.handleSrcChange(link2)}
                        >
                            <List.Header>CalWin Release Notes</List.Header>
                            {vNumber ? `(${vNumber})` : '...'}
                        </List.Item>
                        <List.Item
                            active={currentSrc === link3}
                            onClick={() => this.handleSrcChange(link3)}
                        >
                            <List.Header>CalWin Server Library</List.Header>
                            https://octaossrv1.calwin.no/api
                        </List.Item>
                        <List.Item
                            active={!currentSrc}
                            onClick={() => this.setState({ currentSrc: null })}
                        >
                            <List.Header>CalWin Swagger</List.Header>
                            https://octaossrv1.calwin.no/doc
                        </List.Item>
                        <List.Item onClick={() => window.open("https://calwin.zendesk.com", "_blank")} >
                            <List.Header>CalWin Support</List.Header>
                            https://calwin.zendesk.com
                        </List.Item>
                    </List>
                </Segment>
                <Loader active={iframeLoading} />
                {currentSrc &&
                    <iframe
                        ref={this.iframeElm}
                        title="CalWin Resources"
                        src={currentSrc}
                        style={{ border: 0, width: '100%', height: '65vh' }}
                    />
                }
                <div id="myDomId2" style={currentSrc ? { display: 'none' } : {}} />
            </Fragment>
        )
    }
}

export default CalwinResources
