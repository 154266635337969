import React, { Fragment, useState, useEffect } from 'react';
import { Loader } from 'semantic-ui-react'

import Login from './components/Login'
// import Header from './components/Header'
import Dashboard from './components/dashboard'

import { UserProvider } from './UserContext'

import { isAuthenticated as isAuth, setToken } from './helpers/auth'
import { getUser, setUser as setUserStorage, deleteUser } from './helpers/storage'
import { getUser as getUserApi } from './api/services/user'
import useApi from './api/useApi'

import Testing from './components/dashboard/pages/Testing'
import CalwinReports from './components/dashboard/pages/CalwinReports'

import { getQuery } from './helpers/utils'

const App = () => {
    const { token, env, view } = getQuery()

    if (token && env && view) {
        setToken(token)
        localStorage.setItem("environment", env)

        return <ViewApp />
    }

    return <DefaultApp />
}

const ViewApp = () => {
    const [loadingUser, error, user] = useApi(getUserApi)

    const View = () => {
        const { view } = getQuery()

        switch (view) {
            case "testing":
                return <Testing />
            case "reports":
                return <CalwinReports />
            default:
                return <p>No View</p>
        }
    }

    return (
        <Fragment>
            {loadingUser && (
                <div style={{ position: 'fixed', textAlign: 'center', top: '50%', right: '50%' }} >
                    <Loader active content="Loading" />
                </div>
            )}

            {!loadingUser && error && (
                <pre style={{ color: 'red' }} >{JSON.stringify(error, null, 4)}</pre>
            )}

            {!loadingUser && !error && user && (
                <UserProvider value={user} >
                    <View />
                </UserProvider>
            )}
        </Fragment>
    )
}

const DefaultApp = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(isAuth())
    const [user, setUser] = useState(getUser())
    const [loadingUser, setLoadingUser] = useState(getUser())

    const handleAuthenticationChange = (isAuthenticated, user) => {
        setIsAuthenticated(isAuthenticated)
        setUser(user)
        setUserStorage(user)
    }

    useEffect(() => {
        const fetchUser = async () => {
            setLoadingUser(true)

            try {
                const res = await getUserApi()

                setUser(res.data)
                setUserStorage(res.data)
            } catch (error) {
                setUser(null)
                deleteUser()
            }

            setLoadingUser(false)
        }

        if (isAuthenticated && user) fetchUser()
    }, [])

    return (
        <div className="App">
            {isAuthenticated && user
                ?
                <UserProvider value={user} >
                    {loadingUser && (
                        <div style={{ position: 'fixed', textAlign: 'center', top: 100, right: 100 }} >
                            <Loader active content="Loading" />
                            {/* <p>Loading...</p> */}
                        </div>
                    )}
                    <Dashboard />
                </UserProvider>
                :
                <Fragment>
                    <div style={{ height: 50 }} />
                    {/* <Header /> */}
                    <Login onAuthenticationChange={handleAuthenticationChange} />
                </Fragment>
            }
        </div>
    )
}

export default App;
