import React, { useState } from 'react'
import { Form, Select } from 'semantic-ui-react'

import useApi from '../../../../api/useApi'
import { getManufacturers } from '../../../../api/services/manufacturer'

const SelectManufacturer = ({ onChange, disabled = false }) => {
    const [isLoading, error, list] = useApi(getManufacturers)
    const [selectedManufacturer, setSelectedManufacturer] = useState(null)

    const handleselectedManufacturerChange = value => {
        setSelectedManufacturer(value)

        onChange(value)
    }

    const options = list ? list.map(manufacturer => ({
        key: manufacturer.guid,
        text: manufacturer.name,
        value: manufacturer.guid,
    })) : []

    return (
        <Form>
            <Form.Field
                inline
                control={Select}
                label="Manufacturer"
                placeholder="Manufacturer"
                loading={isLoading}
                error={error}
                options={options}
                disabled={disabled}
                value={selectedManufacturer}
                onChange={(_, { value }) => handleselectedManufacturerChange(value)}
            />
        </Form>
    )
}

export default SelectManufacturer
