import React, { Fragment, Component } from 'react'
import { Table, Dropdown } from 'semantic-ui-react'

import AddModal from './AddModal'
import SelectModal from './SelectModal2'
import Confirm from './Confirm'
import Page from '../../components/Page'

import { UserConsumer } from '../../../../UserContext'
import {
    getUserAttributes,
    addUserAttribute,
    removeUserAttribute,
} from '../../../../api/services/user'
import { getManufacturers } from '../../../../api/services/manufacturer'

class CalwinReports extends Component {
    state = {
        reports: [],
        filteredList: [],
        manufacturers: [],
        loading: false,
        loadingManufacturers: false,
        error: null,
        manufacturersFilter: []
    }

    componentDidMount() {
        this.getUserAttributes()
        this.getManufacturers()
        // addUserAttribute(this.props.context.id, "report_test2", "12345|report")
        // updateUserAttribute(this.props.context.id, "report_test", "12345|report1")
    }


    filterAttributes = attr => attr.filter(att => att.attribute.substring(0, 7) === "report_").map(report => {
        const name = report.attribute.substring(7)

        if (report.value !== "deleted") {
            const values = report.value.split("|")
            return { name, manufacturer: values[0], id: values[1] }
        } else {
            return { name, manufacturer: "deleted", id: "deleted" }
        }
    })

    getUserAttributes = () => {
        this.setState({ loading: true })
        const user = this.props.context
        getUserAttributes(user.id)
            .then(res => {
                const reports = this.filterAttributes(res.data)
                this.setState({ reports, filteredList: reports })
            })
            .catch(e => this.setState({ error: e }))
            .then(() => this.setState({ loading: false }))
    }

    getManufacturers = () => {
        this.setState({ loadingManufacturers: true })
        getManufacturers()
            .then(res => this.setState({ manufacturers: res.data }))
            .catch(e => this.setState({ error: e }))
            .then(() => this.setState({ loadingManufacturers: false }))
    }

    filterList = text => {
        const { reports } = this.state
        const filteredList = reports.filter(report => {
            const reportName = report.name.substring(0, text.length).toLowerCase()

            return reportName === text.toLowerCase()
        })

        this.setState({ filteredList })
    }

    filteredList = list => {
        const { manufacturersFilter } = this.state
        if (manufacturersFilter.length <= 0) {
            return list
        } else {
            return list.filter(report => manufacturersFilter.some(guid => guid === report.manufacturer))
        }
    }

    handleSave = (name, guid, reportId) => {
        this.setState({ loading: true })
        const attrName = `report_${name}`
        const attrValue = `${guid}|${reportId}`

        addUserAttribute(this.props.context.id, attrName, attrValue)
            .then(res => this.getUserAttributes())
            .catch(e => this.setState({ error: e }))
    }

    handleDelete = name => {
        this.setState({ loading: true })
        const attrName = `report_${name}`

        removeUserAttribute(this.props.context.id, attrName)
            .then(res => this.getUserAttributes())
            .catch(e => this.setState({ error: e }))
    }

    getManufacturerName = guid => {
        const { manufacturers } = this.state
        const manufacturer = manufacturers.find(man => man.guid === guid)

        if (manufacturer)
            return manufacturer.name
        else {
            return guid
        }
    }

    render() {
        const { filteredList, loading, loadingManufacturers, manufacturers, error, manufacturersFilter } = this.state

        return (
            <Page
                icon="file text"
                name="CalWin Reports"
                length={this.filteredList(filteredList).length}
                loading={loading}
                error={error}
                onFilterChange={this.filterList}
                tableHeaders={["Report name", "ID", "Manufacturer", ""]}
                tableBody={() => (
                    <Fragment>
                        {this.filteredList(filteredList).map(report =>
                            <Table.Row key={`${report.id}${report.name}`} >
                                <Table.Cell>{report.name}</Table.Cell>
                                <Table.Cell>{report.id}</Table.Cell>
                                <Table.Cell>{loadingManufacturers ? report.manufacturer : this.getManufacturerName(report.manufacturer)}</Table.Cell>
                                <Table.Cell collapsing>
                                    <Fragment>
                                        <SelectModal report={report} guid={report.manufacturer} />
                                        <Confirm onConfirm={() => this.handleDelete(report.name)} />
                                    </Fragment>
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Fragment>
                )}
                extraFilters={() => (
                    <Dropdown
                        placeholder="Filter manufacturer..."
                        multiple
                        selection
                        closeOnChange
                        options={manufacturers.filter(man => man.guid).map(man => ({ key: man.guid, text: man.name, value: man.guid }))}
                        value={manufacturersFilter}
                        onChange={(_, { value }) => this.setState({ manufacturersFilter: value })}
                    />
                )}
                addNew={() => (
                    <AddModal
                        manufacturers={manufacturers}
                        loadingManufacturers={loadingManufacturers}
                        onSave={this.handleSave}
                    />
                )}
            />
        )
    }
}

const MapElement = () => <UserConsumer>{context => <CalwinReports context={context} />}</UserConsumer>

export default MapElement
