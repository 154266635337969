import React, { Fragment, Component } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button, Loader, Form, Grid } from 'semantic-ui-react'
import SwaggerUI from 'swagger-ui'
import 'swagger-ui/dist/swagger-ui.css'
import axios from 'axios'

import { getToken } from '../../../helpers/auth'

const getUrl = () => {
    const environment = localStorage.getItem("environment")

    if (environment) {
        if (environment === "local") return "http://localhost:3000"
        if (environment === "dev") return "https://api-dev.octaos.com"
        if (environment === "stg") return "https://api-stg.octaos.com"
        if (environment === "prod") return "https://api.octaos.com"
    } else {
        return "https://osc-api-srv-dev.azurewebsites.net"
    }
}

class Swagger extends Component {
    constructor(props) {
        super(props)

        this.state = {
            token: getToken(),
            url: getUrl(),
            iframeLoading: false,
            swaggerVersion: "live",
            username: "",
            password: "",
            loadingLogin: false,
            loginError: null,
        }

        this.iframeElm = React.createRef()
    }

    ui

    componentDidMount() {
        const { url, token } = this.state
        this.ui = SwaggerUI({
            dom_id: '#myDomId',
            url: `${url}/docs.json?token=${token}&all=1`,
            onComplete: () => {
                this.ui.preauthorizeApiKey("header", token)
            }
        })
        this.iframeElm.current.addEventListener('load', () => this.setState({ iframeLoading: false }))
        this.setState({ iframeLoading: true })
    }

    login = async () => {
        this.setState({ loadingLogin: true, loginError: null })
        const { username, password, url } = this.state

        try {
            const res = await axios.post(`${url}/auth/login`, { username, password })
            const token = res.data && res.data.token ? res.data.token : null

            this.setState({ loadingLogin: false, token }, () => this.ui.preauthorizeApiKey("header", token))
        } catch (error) {
            this.setState({ loadingLogin: false, loginError: "Fail" })
        }

    }

    render() {
        const { token, url, iframeLoading, swaggerVersion, username, password, loadingLogin, loginError } = this.state

        return (
            <Fragment>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width="2" >
                            <CopyToClipboard text={token} >
                                <Button primary content="Copy token" />
                            </CopyToClipboard>
                        </Grid.Column>
                        <Grid.Column width="2" >
                            <Button.Group>
                                <Button toggle content="Live" active={swaggerVersion === "live"} onClick={() => this.setState({ swaggerVersion: "live" })} />
                                <Button toggle content="Local" active={swaggerVersion === "local"} onClick={() => this.setState({ swaggerVersion: "local" })} />
                            </Button.Group>
                        </Grid.Column>
                        <Grid.Column width="12" >
                            {swaggerVersion === "local" &&
                                <Form>
                                    <Form.Group inline widths="equal" >
                                        <Form.Input
                                            type="text"
                                            label="Username"
                                            value={username}
                                            onChange={e => this.setState({ username: e.target.value })}
                                        />
                                        <Form.Input
                                            type="password"
                                            label="Password"
                                            value={password}
                                            onChange={e => this.setState({ password: e.target.value })}
                                        />
                                        <Form.Button
                                            children={loginError ? loginError : "Switch"}
                                            color={loginError ? "red" : "grey"}
                                            onClick={this.login}
                                            loading={loadingLogin}
                                            disabled={loadingLogin}
                                        />
                                    </Form.Group>
                                </Form>
                            }
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <div style={swaggerVersion !== "live" ? { display: 'none' } : {}} >
                    <Loader active={iframeLoading} />
                    <iframe
                        ref={this.iframeElm}
                        title="api"
                        src={`${url}/swagger-ui?token=${token}`}
                        style={{ border: 0, width: '100%', height: '78vh' }}
                    />
                </div>
                <div id="myDomId" style={swaggerVersion !== "local" ? { display: 'none' } : {}} />
            </Fragment>
        )
    }
}

export default Swagger
