import React, { Fragment, Component } from 'react'
import { Button, Modal, Message } from 'semantic-ui-react'

import { getReport } from '../../../../api/services/report'
import ReportParams from './ReportParams'

const initState = {
    modalOpen: false,
    paramInputs: {},
    gettingReport: false,
    gettingReportError: null,
}

class AddModal extends Component {
    state = {
        ...initState
    }

    getReport = async () => {
        this.setState({ gettingReport: true, gettingReportError: null })

        try {
            const res = await getReport(this.props.report.id, this.state.paramInputs, this.props.guid)

            this.setState({ gettingReport: false })

            if (res && res.data && res.data.file) {
                window.open(res.data.file, "_blank")
            }
        } catch (error) {
            let gettingReportError = error.data

            if (error.status && error.status === 404)
                gettingReportError = "Report not found"

            this.setState({ gettingReportError, gettingReport: false })
        }
    }

    handleOpen = () => {
        this.setState({ modalOpen: true })
    }

    handleClose = () => this.setState({ ...initState })

    render() {
        const { modalOpen, gettingReport, gettingReportError } = this.state
        const { report, guid } = this.props

        return (
            <Fragment>
                <Button primary content="Select" onClick={this.handleOpen} />
                <Modal open={modalOpen} onClose={this.handleClose} >
                    <Modal.Header>{report.name}</Modal.Header>
                    <Modal.Content>
                        <ReportParams guid={guid} reportId={report.id} onParamChange={paramInputs => this.setState({ paramInputs })} />
                        {gettingReportError &&
                            <Message error>
                                <pre>{JSON.stringify(gettingReportError, null, 4)}</pre>
                            </Message>
                        }
                    </Modal.Content>
                    <Modal.Actions>
                        <Button content="Cancel" onClick={this.handleClose} />
                        <Button
                            disabled={gettingReport}
                            color="green"
                            content="Get"
                            loading={gettingReport}
                            onClick={this.getReport} />
                    </Modal.Actions>
                </Modal>
            </Fragment>
        )
    }
}

export default AddModal
