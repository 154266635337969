export const getParamsCount = (func, values, defaultParams) => {
    return func.params.reduce((acc, param) => {
        if ((values[param.name] && values[param.name].length > 0) || (defaultParams[param.name] && defaultParams[param.name].length > 0))
            return acc + 1;

        return acc;
    }, 0)
}

export const getParamsCountColor = (func, values, defaultParams) => {
    const count = getParamsCount(func, values, defaultParams);

    if (func.params.length !== count) return "red"

    return "green"
}