import React, { Fragment } from 'react'
import { Table } from 'semantic-ui-react'

import EditModal from '../components/EditModal'
import Page from '../components/Page'

import useApi from '../../../api/useApi'
import { userSearch } from '../../../api/services/user'

const ApiUsers = () => {
    const [isLoading, error, list] = useApi(userSearch)

    const handleSave = (id, inputs) => {
        console.log(id, inputs)
    }

    const getApiUsersLength = (list) => list.reduce((acc, user) => user.apiUserId ? acc + 1 : acc, 0)

    return (
        <Page
            icon="globe"
            name="API Users"
            length={list ? getApiUsersLength(list) : 0}
            loading={isLoading}
            error={error}
            tableHeaders={[
                "ID",
                "User name",
                "User email",
                "Enabled",
                "Name",
                "",
            ]}
            tableBody={() => (
                <Fragment>
                    {list && list.map((user, i) =>
                        <Fragment key={`${i}${user.apiUserId}${user.apiUsername}${user.email}${user.name}`} >
                            {user.apiUserId &&
                                <Table.Row>
                                    <Table.Cell>{user.apiUserId}</Table.Cell>
                                    <Table.Cell>{user.apiUsername}</Table.Cell>
                                    <Table.Cell>{user.email}</Table.Cell>
                                    <Table.Cell>{user.apiEnabled ? "Yes" : "No"}</Table.Cell>
                                    <Table.Cell>{user.name}</Table.Cell>
                                    <Table.Cell collapsing>
                                        <EditModal
                                            object={user}
                                            header={user.apiUsername}
                                            onSave={inputs => handleSave(user.apiUserId, inputs)}
                                        />
                                    </Table.Cell>
                                </Table.Row>
                            }
                        </Fragment>
                    )}
                </Fragment>
            )}
        />
    )
}

export default ApiUsers