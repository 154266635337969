import React, { useState, useEffect, useRef } from 'react';
import useIsDesktop from '../../../helpers/useIsDesktop';

// const offset = (el) => {
//     const rect = el.getBoundingClientRect(),
//         scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
//         scrollTop = window.pageYOffset || document.documentElement.scrollTop;
//     return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
// }

const Sticky = ({ children }) => {
    const isDesktop = useIsDesktop()
    const divRef = useRef()

    const [useSticky, setUseSticky] = useState(false)

    const handleCheckScroll = () => {
        const handleScroll = () => {
            // const divOffset = offset(divRef.current)

            if (window.pageYOffset > divRef.current.getBoundingClientRect().top) {
                setUseSticky(true)
            } else {
                setUseSticky(false)
            }
        }

        window.addEventListener("scroll", handleScroll);

        handleScroll();

        return () => window.removeEventListener("scroll", handleScroll);
    }

    useEffect(handleCheckScroll)

    if (useSticky)
        return (
            <div
                ref={divRef}
                style={{
                    position: 'fixed',
                    top: isDesktop ? 0 : 64,
                    left: isDesktop ? 64 : 0,
                    right: isDesktop ? 64 : 0,
                    zIndex: 99999,
                }}
            >
                {children}
            </div>
        )

    return <div ref={divRef} >{children}</div>
}

export default Sticky