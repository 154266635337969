import React, { Fragment, useState, useEffect } from 'react'
import { Button, Modal, Icon, Input, Checkbox, Segment, Grid, Table, Popup, Label, Message } from 'semantic-ui-react'

import useApi from '../../../../api/useApi'
import { getCalwinFunctions, runCalwinFunction } from '../../../../api/services/admin'
import SelectManufacturer from '../DevTools/SelectManufacturer'
import { UserConsumer } from '../../../../UserContext'
import { addUserAttribute, getUserAttributes } from '../../../../api/services/user'
import DefaultValues from './DefaultValues'
import { getParamsCount, getParamsCountColor } from './utils'
import Sticky from '../../components/Sticky'

const Testing = ({ user }) => {
    const getAttrKey = (guid = null) => {
        const attrKey = "calwin-function-params"

        if (guid) return `${attrKey}-${guid}`

        if (xMid) return `${attrKey}-${xMid}`

        return attrKey
    }

    const getAttrKeyRunOptions = (guid = null) => {
        const attrKey = "calwin-function-run-options"

        if (guid) return `${attrKey}-${guid}`

        if (xMid) return `${attrKey}-${xMid}`

        return attrKey
    }

    const getAttrKeyDefaultParams = (guid = null) => {
        const attrKey = "calwin-function-default-params"

        if (guid) return `${attrKey}-${guid}`

        if (xMid) return `${attrKey}-${xMid}`

        return attrKey
    }

    const initParams = (functions, guid = null) => {
        let _params = {}
        let _showParams = {}
        let _skipRun = {}
        let _defaultParams = {}
        const savedParams = userAttributes.find(attr => attr.attribute === getAttrKey(guid))
        const savedRunOptions = userAttributes.find(attr => attr.attribute === getAttrKeyRunOptions(guid))
        const savedDefaultParams = userAttributes.find(attr => attr.attribute === getAttrKeyDefaultParams(guid))

        functions.forEach(func => {
            _params[func.name] = {}

            if (func.params && func.params.length > 0) {
                _showParams[func.name] = false
            }

            _skipRun[func.name] = false

            func.params.forEach(param => {
                _params[func.name][param.name] = ""
            })
        })

        if (savedParams) {
            try {
                _params = JSON.parse(savedParams.value)
            } catch (error) {
                console.log(error)
            }
        }

        if (savedRunOptions) {
            try {
                _skipRun = JSON.parse(savedRunOptions.value)
            } catch (error) {
                console.log(error)
            }
        }

        if (savedDefaultParams) {
            try {
                _defaultParams = JSON.parse(savedDefaultParams.value)
            } catch (error) {
                console.log(error)
            }
        }

        setParams(_params)
        setShowParams(_showParams)
        setSkipRun(_skipRun)
        setDefaultParams(_defaultParams)
        setIsChanges(false)
    }

    const [isLoading, error, functions] = useApi(getCalwinFunctions, [], true, initParams)

    const [xMid, setXMid] = useState(null)
    const [currentlyRunning, setCurrentlyRunning] = useState(null)
    const [times, setTimes] = useState({})
    const [responses, setResponses] = useState({})
    const [errors, setErrors] = useState({})
    const [params, setParams] = useState({})
    const [defaultParams, setDefaultParams] = useState({})
    const [showParams, setShowParams] = useState({})
    const [skipRun, setSkipRun] = useState({})
    const [modalOpen, setModalOpen] = useState(false)
    const [modalData, setModalData] = useState(null)
    const [isCancel, setIsCancel] = useState(false)
    const [savingParams, setSavingParams] = useState(false)
    const [savingError, setSavingError] = useState(null)
    const [valuesModalOpen, setValuesModalOpen] = useState(false)
    const [queue, setQueue] = useState([])
    const [isChanges, setIsChanges] = useState(false)
    const [userAttributes, setUserAttributes] = useState(user.attributes)
    // const [showRunOptions, setShowRunOptions] = useState(false)

    const handleRun = async (name, params = {}) => {
        setCurrentlyRunning(name)
        setResponses({ ...responses, [name]: null })
        setErrors({ ...errors, [name]: null })

        const _params = {}

        Object.keys(params).forEach(key => {
            if (!params[key] && defaultParams[key]) {
                _params[key] = defaultParams[key]
            } else {
                _params[key] = params[key]
            }
        })

        try {
            const res = await runCalwinFunction(name, _params, xMid)
            const data = res.data && res.data.result ? res.data.result : "invalid"

            setResponses({ ...responses, [name]: data })
            setTimes({ ...times, [name]: res.data.time })

        } catch (error) {
            setErrors({ ...errors, [name]: error })
        }

        setCurrentlyRunning(null)
    }

    const openResponseModal = funcName => {
        setModalData({ type: "response", name: funcName, data: responses[funcName] })
        setModalOpen(true)
    }

    const openErrorModal = funcName => {
        setModalData({ type: "error", name: funcName, data: errors[funcName] })
        setModalOpen(true)
    }

    const handleRunAll = async () => {
        const _queue = []

        functions.forEach(func => {
            if (!skipRun[func.name]) _queue.push(func.name)
        })

        setQueue(_queue)
        setIsCancel(false)

        const _showParams = showParams
        Object.keys(showParams).forEach(key => _showParams[key] = false)
        setShowParams(_showParams)

        // setShowRunOptions(false)
    }

    const TimeView = ({ time }) => {
        const color = time < 1000 ? "green" : time < 5000 ? "orange" : "red"

        return (
            <span style={{ marginRight: 8, color }} >{time.toFixed(2)} ms</span>
        )
    }

    const updateUserAttributes = async () => {
        const res = await getUserAttributes(user.id)

        if (res.data) setUserAttributes(res.data)
    }

    const handleSave = async () => {
        setSavingError(null)
        setSavingParams(true)

        try {
            await addUserAttribute(user.id, getAttrKey(), JSON.stringify(params))
            await addUserAttribute(user.id, getAttrKeyRunOptions(), JSON.stringify(skipRun))
            await updateUserAttributes()
        } catch (error) {
            setSavingError(error)
        }


        setSavingParams(false)
    }

    const handleGuidChange = guid => {
        setXMid(guid)
        initParams(functions, guid)
    }

    const allParamsShowing = Object.keys(showParams).every(key => showParams[key])

    const setAllShowParams = () => {
        const _showParams = showParams
        Object.keys(showParams).forEach(key => _showParams[key] = !allParamsShowing)
        setShowParams(_showParams)
        setQueue([])
    }

    useEffect(() => {
        if (isCancel) {
            setQueue([])
        } else {
            const run = async () => {
                const funcName = queue.shift()
                const _params = params[funcName]
                await handleRun(funcName, _params)
                setQueue(queue)
            }

            if (queue && queue.length > 0 && currentlyRunning === null) {
                run()
            }
        }
    }, [queue, setQueue, handleRun, currentlyRunning, isCancel])

    return (
        <Fragment>
            {!isLoading && !error && functions && (
                <Fragment>
                    <Sticky>
                        <Segment>
                            <Grid stackable verticalAlign="middle" >
                                <Grid.Row>
                                    <Grid.Column width="4" >
                                        <Button.Group size="huge" >
                                            <Button primary disabled={currentlyRunning !== null} onClick={handleRunAll} >Run All</Button>
                                            <Button secondary disabled={currentlyRunning === null} onClick={() => setIsCancel(true)} >Cancel</Button>
                                        </Button.Group>
                                    </Grid.Column>

                                    <Grid.Column width="4" >
                                        {/* <Checkbox
                                        label="Show run options"
                                        toggle
                                        disabled={currentlyRunning !== null}
                                        checked={showRunOptions}
                                        onChange={() => setShowRunOptions(!showRunOptions)}
                                    /> */}

                                        <SelectManufacturer disabled={currentlyRunning !== null} onChange={handleGuidChange} />
                                    </Grid.Column>

                                    <Grid.Column width="8" textAlign="right" >
                                        <Button
                                            color="green"
                                            disabled={savingParams || currentlyRunning !== null || !isChanges}
                                            loading={savingParams}
                                            onClick={handleSave}
                                        >
                                            Save <Icon name='save' />
                                        </Button>
                                        <Button disabled={currentlyRunning !== null} onClick={() => setValuesModalOpen(true)} >Default params</Button>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Sticky>

                    {savingError && (
                        <Message style={{ overflowX: 'scroll' }} negative >
                            <Message.Header>Error saving data</Message.Header>
                            <pre>{JSON.stringify(savingError, null, 4)}</pre>
                        </Message>
                    )}

                    <Table fixed >
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell width="3" ></Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                                <Table.HeaderCell width="5" >
                                    {currentlyRunning === null && (
                                        <Checkbox
                                            label="Show all params"
                                            toggle
                                            checked={allParamsShowing}
                                            onChange={setAllShowParams}
                                        />
                                    )}
                                </Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                                <Table.HeaderCell width="1" ></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {functions.map(func => (
                                <Table.Row key={func.GUID} >
                                    <Table.Cell>
                                        <Popup
                                            content={func.name}
                                            trigger={<span>{func.name}</span>}
                                        />
                                    </Table.Cell>

                                    <Table.Cell>
                                        {currentlyRunning === null && (
                                            <Checkbox
                                                label="Skip auto run"
                                                toggle
                                                checked={skipRun[func.name]}
                                                onChange={() => {
                                                    setSkipRun({ ...skipRun, [func.name]: !skipRun[func.name] })
                                                    if (!isChanges) setIsChanges(true)
                                                }}
                                            />
                                        )}
                                    </Table.Cell>

                                    <Table.Cell>
                                        {showParams[func.name] !== undefined && currentlyRunning === null && (
                                            <span>
                                                <Checkbox
                                                    label="Show params"
                                                    toggle
                                                    checked={showParams[func.name]}
                                                    onChange={() => setShowParams({ ...showParams, [func.name]: !showParams[func.name] })}
                                                />
                                                <Label style={{ marginLeft: 4 }} color={getParamsCountColor(func, params[func.name], defaultParams)} >
                                                    {getParamsCount(func, params[func.name], defaultParams)}
                                                    /
                                                    {func.params.length}
                                                </Label>
                                            </span>
                                        )}
                                        {showParams[func.name] && (
                                            <Fragment>
                                                {func.params.map(param => (
                                                    <Fragment key={param.name} >
                                                        <Input
                                                            style={{ marginTop: 8 }}
                                                            label={param.name}
                                                            placeholder={defaultParams[param.name]}
                                                            value={params[func.name][param.name]}
                                                            onChange={e => {
                                                                setParams({ ...params, [func.name]: { ...params[func.name], [param.name]: e.target.value } })
                                                                if (!isChanges) setIsChanges(true)
                                                            }}
                                                        />
                                                    </Fragment>
                                                ))}
                                            </Fragment>
                                        )}
                                    </Table.Cell>

                                    <Table.Cell>
                                        {responses[func.name] && (
                                            <Fragment>
                                                {responses[func.name] === "invalid" ?
                                                    <span style={{ color: 'red' }} >Invalid response</span>
                                                    :
                                                    <Button color="green" onClick={() => openResponseModal(func.name)}  >View response</Button>
                                                }
                                            </Fragment>
                                        )}

                                        {errors[func.name] && <Button color="red" onClick={() => openErrorModal(func.name)} >View error</Button>}

                                        {currentlyRunning === func.name && <Icon loading name='spinner' />}

                                        {queue && queue.length > 0 && skipRun[func.name] && <span>Skip</span>}
                                    </Table.Cell>

                                    <Table.Cell>
                                        {times[func.name] && <TimeView time={times[func.name]} />}
                                    </Table.Cell>

                                    <Table.Cell textAlign="right" >
                                        <Button disabled={currentlyRunning !== null} onClick={() => handleRun(func.name, params[func.name])} >Run</Button>
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </Fragment>
            )}

            <Modal size="large" open={modalOpen} onClose={() => setModalOpen(false)} >
                <Modal.Header>{modalData && modalData.type === "error" ? "Error" : "Response"} {modalData && modalData.name}</Modal.Header>
                <Modal.Content scrolling >
                    <Modal.Description>
                        <pre>{modalData && JSON.stringify(modalData.data, null, 4)}</pre>
                    </Modal.Description>
                </Modal.Content>
            </Modal>

            <DefaultValues
                functions={functions}
                defaultParams={defaultParams}
                getAttrKeyDefaultParams={getAttrKeyDefaultParams}
                user={user}
                modalOpen={valuesModalOpen}
                onClose={() => setValuesModalOpen(false)}
                onSave={params => {
                    setDefaultParams(params)
                    updateUserAttributes()
                }}
            />
        </Fragment>
    )
}

const MapElement = () => <UserConsumer>{context => <Testing user={context} />}</UserConsumer>

export default MapElement
