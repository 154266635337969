import React, { Fragment } from 'react'

const Logo = ({ animation }) => (
    <Fragment>
        {animation !== "none" &&
            <link rel="stylesheet" type="text/css" href={`./logoStyles/${animation}.css`} />
        }
        <svg
            style={{ overflow: 'visible' }}
            className="arch-1"
            width="100px"
            height="63px"
            viewBox="0 0 100 63"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xlink="http://www.w3.org/1999/xlink"
        >
            <title>Octaos</title>
            <desc>Octaos icon</desc>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <polygon id="icon-upper-left" fill="#5950B7" points="28 0 28 28 0 28"></polygon>
                <polygon id="icon-upper-center" fill="#5950B7" points="32 0 68 0 68 28 32 28"></polygon>
                <polygon id="icon-upper-right" fill="#5950B7" points="72 0 100 28 72 28"></polygon>
                <polygon id="icon-lower-left" fill="#5950B7" points="0 32 28 32 28 63 0 63"></polygon>
                <polygon id="icon-lower-center-left" fill="#5950B7" points="32 32 44 32 32 45"></polygon>
                <polygon id="icon-lower-center-right" fill="#5950B7" points="55 32 68 32 68 45"></polygon>
                <polygon id="icon-lower-right" fill="#5950B7" points="72 32 100 32 100 63 72 63"></polygon>
            </g>
        </svg>
    </Fragment>
)

export default Logo